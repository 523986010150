import AssignConditionType from "../@types/services/AssignConditionType";
import api from "./api";

export const fetchAssignConditions = async () => {
  const response = await api.get("/assign_conditions", {
    baseURL: "/vbuyer/api/v2",
  });
  return response.data;
};

export const fetchManageUserAssignConditions = async (userId: string) => {
  const response = await api.get(
    `/assign_conditions/${userId}/manage_user_assign_conditions`,
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
  return response.data;
};

export const updateAssignConditions = async (
  userId: string,
  assignConditions: AssignConditionType[],
) => {
  const response = await api.put(
    `/assign_conditions/${userId}`,
    {
      assign_condition_ids: assignConditions.map(
        (assignCondition) => assignCondition.id,
      ),
    },
    {
      baseURL: "/vbuyer/api/v2",
    },
  );
  return response.data;
};
